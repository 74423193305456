<!--
 * @Description: 批量修改房价
 * @Author: 琢磨先生
 * @Date: 2022-06-17 01:12:06
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-08-16 12:30:42
-->

<template>
  <div v-power="'seller/v1/housePrice/batch/update'">
    <el-button icon="EditPen" @click="visible = true">批量改价</el-button>
  </div>
  <el-drawer
    title="批量改价"
    v-model="visible"
    direction="rtl"
    size="1350px"
    custom-class="drawer-price"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="true"
  >
    <el-scrollbar>
      <el-timeline>
        <el-timeline-item>
          <p>1、选择房源</p>
          <div>
            <el-alert
              type="info"
              :closable="false"
              :title="`已选 ${selectionList.length} 个房源`"
            >
              <el-tag
                :closable="true" 
                v-for="(item, i) in selectionList"
                :key="item.id"
                style="margin-right: 20px; margin-bottom: 10px"
                @close="selectionList.splice(i, 1)"
              >
                {{ item.name }}(<span style="color:var(--el-color-danger)">房间：{{ item.stores.map(x=>x.house_no).join(',') }}</span>)
              </el-tag>
            </el-alert>
            <el-popover
              :visible="visiblePopover"
              :width="800"
              placement="bottom-start"
              trigger="click"
            >
              <template #reference>
                <el-button
                  type="primary"
                  link
                  icon="plus"
                  @click="visiblePopover = !visiblePopover"
                  >添加房源</el-button
                >
              </template>
              <el-form :model="query" ref="form" :inline="true">
                <el-form-item label>
                  <el-input
                    v-model="query.q"
                    placeholder="房源、内部名称"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    round
                    @click="onSearch"
                    icon="search"
                    >查询</el-button
                  >
                  <el-button round @click="visiblePopover = false" icon="close"
                    >关闭</el-button
                  >
                </el-form-item>
              </el-form>

              <el-table
                ref="houseTable"
                :data="tableData.data"
                @selection-change="selectionChange"
                height="400"
                row-key="id"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  reserve-selection
                ></el-table-column>
                <el-table-column
                  label="编号"
                  prop="id"
                  width="80"
                ></el-table-column>
                <el-table-column label="房源标题" prop="name">
                  
                </el-table-column>
                <el-table-column 
                  label="关联房间" 
                >
                <template #default="scope">
                  <el-tag size="small" v-for="item in scope.row.stores" :key="item.id" style="margin-right:5px;">{{ item.house_no}}</el-tag>
                </template>
              </el-table-column>
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <el-tag
                      :type="
                        scope.row.status == 0
                          ? 'info'
                          : scope.row.status == 5
                          ? ''
                          : scope.row.status == 10
                          ? 'success'
                          : scope.row.status == 15
                          ? 'danger'
                          : ''
                      "
                      >{{ scope.row.status_text }}</el-tag
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                v-if="tableData.counts > 0"
                @size-change="pageSizeChange"
                @current-change="pageCurrentChange"
                :current-page="query.pageIndex"
                :page-size="query.pageSize"
                :page-sizes="[20, 40]"
                layout="total,prev, pager, next"
                :total="tableData.counts"
              ></el-pagination>
            </el-popover>
            <el-button type="info" link icon="remove" @click="onClearSelection"
              >清除所有</el-button
            >
          </div>
        </el-timeline-item>
        <el-timeline-item>
          <p>2、选择日期</p>
          <el-form
            :model="form"
            ref="form"
            :inline="false"
            label-position="top"
          >
            <el-row :gutter="20">
              <!-- <el-col :span="8">
                <el-form-item label="节假日">
                  <el-checkbox-group v-model="form.holiday_ids" @change="holidayChange">
                    <el-checkbox
                      :label="item.id"
                      v-for="item in holidayList"
                      :key="item.id"
                    >{{ item.type_text }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="日期选择">
                  <div style="width: 100%">
                    <!-- <div class="row_date_picker">
                      <el-date-picker
                        v-model="form.dates"
                        :disabled-date="pickerDisabled"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="datePickerChange"
                      />
                      <div style="width:50px;"></div>
                    </div>-->
                    <div
                      class="row_date_picker"
                      v-for="(item, i) in date_pickers"
                      :key="item"
                      style="margin-top: 10px"
                    >
                      <el-date-picker
                        v-model="item.dates"
                        :disabled-date="pickerDisabled"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="datePickerChange"
                      />
                      <div style="width: 50px">
                        <el-button
                          type="danger"
                          link
                          icon="RemoveFilled"
                          v-if="i != 0"
                          @click="datePickerRemove(i)"
                        ></el-button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <el-button
                      type="primary"
                      link
                      icon="CirclePlusFilled"
                      @click="date_pickers.push({})"
                      >添加日期段</el-button
                    >
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="适用周几">
                  <el-checkbox-group v-model="form.week" @change="weekChange">
                    <el-checkbox :label="0">周日</el-checkbox>
                    <el-checkbox :label="1">周一</el-checkbox>
                    <el-checkbox :label="2">周二</el-checkbox>
                    <el-checkbox :label="3">周三</el-checkbox>
                    <el-checkbox :label="4">周四</el-checkbox>
                    <el-checkbox :label="5">周五</el-checkbox>
                    <el-checkbox :label="6">周六</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div class="calendar">
            <div class="volume" v-for="item in calendarList" :key="item.id">
              <div class="title">{{ item.year_month }}</div>
              <div class="grid">
                <div class="week">日</div>
                <div class="week">一</div>
                <div class="week">二</div>
                <div class="week">三</div>
                <div class="week">四</div>
                <div class="week">五</div>
                <div class="week">六</div>
                <div
                  class="item"
                  v-for="x in item.values[0].week"
                  :key="x"
                ></div>
                <div
                  :class="`item ${k.is_check && !k.is_stop ? 'check' : ''}${
                    k.is_stop ? ' stop' : ''
                  }`"
                  v-for="k in item.values"
                  :key="k.date"
                  @click="onToggle(k)"
                >
                  <div class="date">
                    {{ k.day }}
                    <span class="rest" v-if="k.is_statutory && !k.is_stop"
                      >休</span
                    >
                    <span class="jr" v-if="!k.is_stop">{{ k.holiday }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-timeline-item>
        <el-timeline-item>
          <p>3、设置价格</p>
          <div>
            <el-form :model="priceForm" ref="priceForm" label-width="180px">
              <el-form-item label="价格同一设置为">
                <el-col :span="5" :offset="0">
                  <el-input
                    v-model="priceForm.decimal_price"
                    placeholder="请输入金额"
                    @input="basePriceChange"
                  ></el-input>
                </el-col>
              </el-form-item>
            </el-form>
          </div>
          <el-table
            :data="selectionList"
            border
            height="500px"
            v-if="selectionList.length > 0"
          >
            <el-table-column label="名称">
              <template #default="scope">
                {{ scope.row.name }}
                    <el-tag
                      :type="
                        scope.row.status == 0
                          ? 'info'
                          : scope.row.status == 5
                          ? ''
                          : scope.row.status == 10
                          ? 'success'
                          : scope.row.status == 15
                          ? 'danger'
                          : ''
                      "
                      >{{ scope.row.status_text }}</el-tag
                    >
                  </template>
            </el-table-column>
            <el-table-column
              label="关联房间"  
            >
            <template #default="scope">
                <el-tag style="margin-right:5px;" v-for="item in scope.row.stores" :key="item.id" size="small">{{  item.house_no}}</el-tag>
            </template>
          
          </el-table-column>
            <el-table-column label="价格" width="200">
              <template #default="scope">
                <el-col :span="20" :offset="0">
                  <el-input
                    v-model="scope.row.decimal_price"
                    placeholder="￥价格"
                  ></el-input>
                </el-col>
              </template>
            </el-table-column>
            <el-table-column label width="100">
              <template #default="scope">
                <el-button
                  type="danger"
                  link
                  size="small"
                  @click="selectionList.splice(scope.$index, 1)"
                  class="text-danger"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-timeline-item>
      </el-timeline>
    </el-scrollbar>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmit"
        >确定</el-button
      >
    </template>
  </el-drawer>
</template>

<script>
import common_api from "@/http/common_api";
import shop_api from "@/http/shop_api";

export default {
  data() {
    return {
      visible: false,
      visiblePopover: false,
      saving: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //房源列表
      tableData: {
        counts: 0,
      },
      //已选择的房源列表
      selectionList: [],
      form: {},
      //节假日
      holidayList: [],
      //日期
      calendarList: [],
      //选中的节假日
      checkHolidayList: [],
      //选中的星期
      checkWeekList: [],
      //
      priceForm: {},
      //日期选择列表
      date_pickers: [{}],
    };
  },
  created() {
    this.loadCalendar();
    this.loadData();
    common_api.get_holiday().then((res) => {
      if (res.code == 0) {
        this.holidayList = res.data;
      }
    });
    //门店
    shop_api.get_shops().then((res) => {
      if (res.code == 0) {
        this.shop_list = res.data;
      }
    });
  },
  methods: {
    /**
     * 搜索数据
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/house", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 选择发生变化
     */
    selectionChange(nodes) {
      nodes.forEach((item) => {
        if (!this.selectionList.find((x) => x.id == item.id)) {
          this.selectionList.push(item);
        }
      });
    },
    /**
     * 清除所有选择
     */
    onClearSelection() {
      this.selectionList = [];
      this.$refs.houseTable.clearSelection();
    },
    /**
     * 加载日历数据
     */
    loadCalendar() {
      this.$http.get("seller/v1/housePrice/batch/calendar").then((res) => {
        if (res.code == 0) {
          this.calendarList = res.data;
        }
      });
    },
    /**
     * 日期禁用
     */
    pickerDisabled(item) {
      var dt = new Date();
      return item < new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
    },
    /**
     * 日期点击更改
     */
    onToggle(item) {
      item.is_check = !item.is_check;
      console.log(item);
    },
    /**
     * 日期选择更改
     */
    datePickerChange() {
      var temp_list = [];
      this.date_pickers.forEach((picker) => {
        if (picker.dates) {
          var start = picker.dates[0].getTime();
          var end = picker.dates[1].getTime();
          var s = (end - start) / 3600 / 24 / 1000;
          for (let i = 0; i <= s; i++) {
            var dt = new Date(start + i * 3600 * 24 * 1000);
            if (!temp_list.find((o) => o == dt.getTime())) {
              temp_list.push(dt.getTime());
            }
          }
        }
      });
      this.calendarList.forEach((item) => {
        item.values.forEach((x) => {
          var dt = new Date(x.date);
          var t = new Date(
            dt.getFullYear(),
            dt.getMonth(),
            dt.getDate()
          ).getTime();
          x.is_check = temp_list.findIndex((o) => o == t) > -1;
        });
      });
    },

    /**
     * 移除日期列表
     */
    datePickerRemove(i) {
      this.date_pickers.splice(i, 1);
      this.datePickerChange();
    },

    /**
     * 节假日选择框
     */
    holidayChange(values) {
      this.checkHolidayList = [];
      values.forEach((val) => {
        var item = this.holidayList.find((x) => x.id == val);
        this.checkHolidayList = this.checkHolidayList.concat(item.date_list);
      });
      this.calendarList.forEach((item) => {
        item.values.forEach((x) => {
          if (this.checkWeekList.findIndex((o) => o == x.week) == -1) {
            x.is_check =
              this.checkHolidayList.findIndex((o) => o == x.date) > -1;
          }
        });
      });
    },
    /**
     * 星期多选框
     */
    weekChange(values) {
      this.checkWeekList = values;
      this.calendarList.forEach((item) => {
        item.values.forEach((x) => {
          if (this.checkHolidayList.findIndex((o) => o == x.date) == -1) {
            x.is_check = this.checkWeekList.findIndex((o) => o == x.week) > -1;
          }
        });
      });
    },
    /**
     * 同一价格设置更改
     */
    basePriceChange(val) {
      var reg = /^\d+(\.\d{1,2})?$/;
      if (reg.test(val)) {
        this.selectionList.forEach((item) => {
          item.decimal_price = val;
        });
      }
    },
    /**
     * 确定提交
     */
    onSubmit() {
      if (this.selectionList.length == 0) {
        this.$message("请选择房源");
        return;
      }
      var calendar = [];
      this.calendarList.forEach((item) => {
        item.values.forEach((x) => {
          if (x.is_check) {
            calendar.push(x);
          }
        });
      });
      if (calendar.length <= 0) {
        this.$message("请选择日期");
        return;
      }

      for (let i = 0; i < this.selectionList.length; i++) {
        if (!this.selectionList[i].decimal_price) {
          this.$message(`请设置${this.selectionList[i].private_name}的价格`);
          return;
        }
      }
      console.log(this.selectionList);
      this.saving = true;

      this.$http
        .post("seller/v1/housePrice/batch/update", {
          houses: this.selectionList,
          date_list: calendar,
        })
        .then((res) => {
          this.saving = false;
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          }
        })
        .catch(() => (this.saving = false));
    },
  },
};
</script>

<style  >
.drawer-price .el-timeline-item {
  line-height: normal;
}

.drawer-price .el-timeline-item p {
  font-size: 16px;
}

.drawer-price .calendar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 30px;
  column-gap: 30px;
  margin-top: 20px;
}

.drawer-price .volume {
  width: 280px;
}

.drawer-price .volume .title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
}

.drawer-price .volume .grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* row-gap: 30px; */
  /* column-gap: 30px; */
}

.drawer-price .volume .grid .week {
  margin-bottom: 5px;
  color: #999;
  font-size: 12px;
  /* font-weight: 500; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawer-price .volume .grid .item {
  width: 40x;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /* border: 1px solid #333; */
}

.drawer-price .volume .grid .item.check {
  background: #409eff;
}

.drawer-price .grid .item.stop {
  background: #f5f7fa;
  color: #cdd0d6;
}

.drawer-price .grid .rest {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  color: #f56c6c;
  transform: scale(0.9);
}

.drawer-price .grid .jr {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  color: #67c23a;
  text-align: center;
  transform: scale(0.9);
}

.row_date_picker {
  display: flex;
  flex-direction: row;
}

.drawer-price .el-drawer__footer {
  text-align: center;
}
</style>